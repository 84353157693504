html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

div#website {
  position: relative; /* needed for footer positioning*/
  margin: 0 auto; /* center, not in IE5 */
  height: auto !important; /* real browsers */
  height: 100%; /* IE6: treaded as min-height*/
  min-height: 100vh; /* real browsers */
}

div#header {
  padding: 0.2em;
}

div#content {
  padding: 1em 1em 5em; /* bottom padding for footer */
}

div#footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* position: absolute;
    bottom: 0;
    /* line-height: 30px; /* Vertically center the text there */
}

//Wemint color
//$primary: #00b48c;
//$wemint: #00b48c;

//.minitext {
//	font-size: 0.875rem;
//}

.usedbar {
  height: 1px;
}

.center {
  display: flex;
  justify-content: center;
}

/*
.container {
  max-width: 960px !important;
}

.container960 {
  max-width: 960px !important;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


/*.p-header,
.pt-header,
.py-header {
  padding-top: 1.5rem !important;
}
*/
.lh-100 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25;
}

.lh-150 {
  line-height: 1.5;
}

.thin-1 {
  font-size: 6rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-2 {
  font-size: 5.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-3 {
  font-size: 4.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-4 {
  font-size: 3.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-5 {
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-6 {
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1.2;
}

@import "~bootstrap/scss/bootstrap";
